<template>
    <BackofficeBase :loader_prop="loader_prop">
        <body class="dash__back">
	<div class="overlay"></div>
	<div class="dashboard__wrapper">
		<div class="dashboard__main">
			<div class="report__wrapper">
				<div class="breadcrumbs">
					<ul>
            <li><router-link :to="{ path: '/backoffice/dashbord' }">ניהול עובדים</router-link></li>
						<li class="spacer">></li>
            <li class="current">דיווח -  <span> {{full_name}} </span></li>
          </ul>
				</div>
				<div class="report__form">
					<!-- <form action=""> -->
						<div class="group__input medium">
							<p>דיווח לתאריך</p>
							<input type="date" class="date__picker bottom" v-model="working_date" @input="handleDateChange" >
						</div>
						<div class="report__group">
							<p>דיווח שעות:</p>
							<div class="group__input number">
								<p>נוכחות</p>
								<input type="time" v-model="minimum_daily_hours" >
							</div>
							<div class="group__input number">
								<p>שעות עבודה</p>
								<input type="time" v-model="working_hours" @input="handle_actual_working_hours_change">
							</div>
						</div>
						<div class="layer__wrapper">
                            
							<p>פרמיות:</p>
							<div class="layer__list" >
                <div class='elem__layer' v-for="prem in daily_output_report" :key = "prem.id" >
                  <template v-if="prem.is_delete != 1">
									<div class='group__input group__dropdown' >
										<p>סוג המשטח</p>
										<!-- <a href='#'  @click="prem.showDropdown = !prem.showDropdown"><span>{{ prem.premium_name }}</span> <img src='../../assets/img/arrow.svg' alt='arrow'></a> -->
										<a href="#" @click.prevent="prem.showDropdown = !prem.showDropdown">
                      <span>{{ prem.premium_name }}</span>
                      <img src='../../assets/img/arrow.svg' alt='arrow'>
                    </a>
                    <!-- <div class='dropdown__box' :style="{ display: prem.showDropdown ? 'block' : 'none' }">
											<ul>
                        <li v-for="pallet in pallets" :key="pallet.id">
                          <a href="#" @click="updatePremiumId(prem, pallet.id)">
                            {{pallet.premium_name}}
                          </a>
                        </li>											
                      </ul>
										</div> -->
                    <div class='dropdown__box' :style="{ display: prem.showDropdown ? 'block' : 'none' }">
                      <input type="text" v-model="searchQuery" placeholder="חפש...">
                      <ul>
                        <li v-for="pallet in filteredPallets" :key="pallet.id">
                          <a href="#" @click="updatePremiumId(prem, pallet.id)">
                              {{pallet.premium_name}}
                          </a>
                        </li>											
                      </ul>
                    </div>
									</div>
									<div class='group__input_amount number'>
										<p>כמות</p>
										<input type='number' placeholder='' min="0" v-model="prem.amount">
									</div>
									<div class='remove'  @click="deletePrem(prem)">
										<a href='#'><span><img src='../../assets/img/remove.svg' alt='remove'></span> מחיקת משטח</a>
									</div>
                  </template>
								</div>
							</div>
							<div class="add__layer"  >
								<a href="#" @click="add_pallet($event)"><span><img src="../../assets/img/add.svg" alt="add"></span> הוסף משטח</a>              
              </div>
              <!-- <div class="add__layer">
    <button @click="add_pallet"><span><img src="../../assets/img/add.svg" alt="add"></span> הוסף משטח</button>
</div> -->
                           
            <div v-if="err">
              <p class="err_text">{{ err_text }}</p>
            </div>
						</div>
						<div class="controls">
							<button type="submit" class="regular-btn" @click="submit_from">שמירה</button>
							<a href="#" class="outline-btn" @click="refreshPage">ביטול</a>
						</div>
					<!-- </form> -->
				</div>
			</div>
		</div>
        <BackofficeSidebar></BackofficeSidebar>
	</div>

	<div class="modal__wrapper" style="display:none;">
		<div class="inner__modal">
			<div class="modal__box export">
				<a href="#"><img src="../../assets/img/close.svg" alt="close"></a>
				<form action="">
					<h2>הנפקת דו”ח</h2>
					<div class="group__input group__dropdown">
						<p>בחירת חודש</p>
						<a href="#"><span>מאי 2023</span> <img src="../../assets/img/arrow.svg" alt="arrow"></a>
						<div class="dropdown__box" style="display:none;">
							<ul>
								<li><a href="#">1</a></li>
								<li><a href="#">2</a></li>
								<li><a href="#">3</a></li>
								<li><a href="#">4</a></li>
								<li><a href="#">5</a></li>
								<li><a href="#">1</a></li>
								<li><a href="#">2</a></li>
								<li><a href="#">3</a></li>
								<li><a href="#">4</a></li>
								<li><a href="#">5</a></li>
							</ul>
						</div>
					</div>
					<div class="controls">
						<button class="regular-btn">הנפק דו”ח</button>
						<a href="#" class="outline-btn cancel__button">ביטול</a>
						
					</div>
				</form>
			</div>
		</div>
	</div>

</body>
    </BackofficeBase>
  </template>
  
  <script>
  import BackofficeBase from "./BackofficeBase";
  import BackendModel from "../../models/BackendModel";
  import BackofficeSidebar from "./BackofficeSidebar";
  
  export default {
    name: "BackofficeEditAdmin",
    components: {
      BackofficeBase,
      BackofficeSidebar
      
    },
    
    data() {
      return {
        loader_prop: true,
        id: "0",
        full_name:"",
        working_date: "",
        working_hours:"",
        minimum_daily_hours:"",
        // daily_output_report:[{id:1,premium_id:"",id_front:1,is_delete:0,amount:0 , premium_name:"ddm"},{id:2,premium_id:"", id_front:2,is_delete:0, amount:0, premium_name:"xxx"}],
        daily_output_report:[],
        err: false,
        err_text: "",
        pallets:[],
        searchQuery: "",
      };
    },
    computed: {
      filteredPallets() {
          return this.pallets.filter(pallet => pallet.premium_name.toLowerCase().includes(this.searchQuery.toLowerCase()));
      }
    },
    async mounted() {
      this.loader_prop= true
    this.id = this.$route.query.id    
    this.working_date = this.$route.query.working_date   
 
    let backendModel = new BackendModel()
    if (this.id!=0){
        let data = await backendModel.backendRequest("/Api/service/backoffice/get_report_by_id_and_date_to_edit",{"id" : this.id, working_date: this.working_date})
        // this.data = data.data.data.employee
        this.data = data.data.data
        this.working_date = this.data.working_date
        this.minimum_daily_hours = this.data.minimum_daily_hours
     
        this.working_hours = this.data.working_hours
        this.full_name = this.data.full_name
        // daily_output_report
        this.daily_output_report = this.data.daily_output_report
        this.pallets = this.data.pallets
        this.loader_prop= false
        console.log(this.data)
    }
    },

    methods: {

      refreshPage() {
        this.$router.push({ path:  '/backoffice/user_report' ,query : {'id' : this.id}}) 
      },
      toggleCheckbox() {
        if (this.is_active) {
          this.end_date = "";
        }
      },

      async handleDateChange() {
      // פונקציה שמופעלת כאשר התאריך משתנה
      this.$route.query.working_date = this.working_date
      console.log('התאריך שונה:', this.working_date);
      let backendModel = new BackendModel()
      if (this.id!=0){
        let data = await backendModel.backendRequest("/Api/service/backoffice/get_report_by_id_and_date_to_edit",{"id" : this.id, working_date: this.working_date})
        // this.data = data.data.data.employee
        this.data = data.data.data
        this.working_date = this.data.working_date
        this.minimum_daily_hours = this.data.minimum_daily_hours
        this.working_hours = this.data.working_hours
        this.full_name = this.data.full_name
        // daily_output_report
        this.daily_output_report = this.data.daily_output_report
        this.pallets = this.data.pallets
        this.loader_prop= false
        console.log(this.data)
    }
      // הוסף את הפעולות שאתה רוצה לבצע כאשר התאריך משתנה
    },
      add_pallet (event) {
        event.preventDefault();
        window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' });
         const newObj = {
           id: 0,
           amount: 0,
           premium_id: 0,
           id_front: 1,
           is_delete: 0
         };
         if (this.daily_output_report.length === 0) {
           this.daily_output_report.push(newObj);
         } else {
           const maxIdFront = Math.max(...this.daily_output_report.map(obj => obj.id_front));
           newObj.id_front = maxIdFront + 1;
           this.daily_output_report.push(newObj);
         }
       },
       deletePrem(prem) {
         prem.is_delete = 1;
         console.log(this.daily_output_report)
       },
       updatePremiumId(prem, premiumId) {
        prem.premium_id = premiumId;
        prem.showDropdown = false;
        const selectedPallet = this.pallets.find((pallet) => pallet.id === premiumId);
        if (selectedPallet) {
        prem.premium_name = selectedPallet.premium_name;
        }
      },
      handle_actual_working_hours_change(event) {
      const selectedTime = event.target.value;
      console.log(selectedTime);
    },

      send_data(){
        console.log(
        this.daily_output_report,
        this.minimum_daily_hours,
        this.working_date
        )
      },

      async submit_from(e) {
        e.preventDefault();
        let err_counter = 0;
        // if (
        //   this.id == "" ||
        //   this.id == 0 ||
        //   this.id == "null" ||
        //   typeof this.id == "null" ||

        // ) {
        //   err_counter++;
        //   this.err = true;
        //   this.err_text = "לא לגעת בurl";
        // }
        // if(this.id =='0'&& this.password=="")
        // {
        //   err_counter++;
        //   this.err = true;
        //   this.err_text = "משתמש חדש הזן סיסמה";
        // }
        // if (!/^[a-zA-Z0-9_$]+@[a-zA-Z0-9_$]+\.[a-zA-Z]+$/.test(this.email)) {
        //   err_counter++;
        //   this.err = true;
        //   this.err_text = "הזן כתובת מייל תקינה";
        // }


        // console.log(err_counter)
        if (err_counter == 0) {
          this.loader_prop = true;

 
          let backendModel = new BackendModel()
          let params = {
            id: this.id ,
            working_date: this.working_date ,
            minimum_daily_hours: this.minimum_daily_hours ,
            daily_output_report: this.daily_output_report ,
            working_hours: this.working_hours ,
          }
          let res = await backendModel.backendRequest("/Api/service/backoffice/save_employee_report", params)
          console.log(res)
          if(res.data.res =="OK"){
            await this.$router.push({ path:  '/backoffice/user_report' ,query : {'id' : this.id}} )
            // console.log("OK")
          }else{ 
            this.err = true;
            this.err_text =res.data.msg
          }
          this.loader_prop = false
        }
    },

    },
  };
  </script>
  <style>
  @import "../../assets/new_style.css";
  </style>
  